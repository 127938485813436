<template>
  <v-dialog
    v-model="isOpen"
    width="340"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :label="label"
        placeholder="Input tanggal dan waktu"
        :value="formattedDateTime"
        :rules="rules"
        readonly
        outlined
        dense
        :hide-details="details"
        v-on="on"
      />
    </template>

    <v-card>
      <v-card-text class="pa-0">
        <v-tabs
          v-model="activeTab"
          fixed-tabs
        >
          <v-tab>
            <v-icon>
              {{ icons.mdiCalendar }}
            </v-icon>
          </v-tab>
          <v-tab
            :disabled="validDate"
          >
            <v-icon>
              {{ icons.mdiClockTimeThree }}
            </v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="activeTab"
        >
          <v-tab-item>
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              full-width
              no-title
              locale="id-ID"
              @input="showTimePicker"
            />
          </v-tab-item>
          <v-tab-item>
            <v-time-picker
              ref="timePicker"
              v-model="time"
              v-bind="timePickerProps"
              locale="id-ID"
              full-width
              format="24hr"
              class="mb-8"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions class="mt-n4">
        <v-spacer />
        <v-btn
          text
          outlined
          color="primary"
          @click="clearHandler"
        >
          Reset
        </v-btn>
        <v-btn
          color="primary"
          @click="confirmHandler"
        >
          Pilih
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { mdiCalendar, mdiClockTimeThree } from '@mdi/js'
import { format, parseISO } from 'date-fns'
import dateFormat from '@/utils/dateFormat'

export default {
  props: {
    value: {
      type: [Date, String],
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    details: {
      type: [Boolean, String],
      default: 'auto',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    textFieldProps: {
      type: Object,
      default: () => {},
    },
    datePickerProps: {
      type: Object,
      default: () => {},
    },
    timePickerProps: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const activeTab = ref(0)
    const date = ref('')
    const time = ref('00:00:00')
    const timePicker = ref()

    const validDate = computed(() => !date.value)
    const selectedDatetime = computed(() => {
      if (date.value && time.value) {
        const dateTimeString = `${date.value}T${time.value}`

        return dateTimeString
      }

      return null
    })
    const formattedDateTime = computed(() => {
      if (selectedDatetime.value) {
        return dateFormat(selectedDatetime.value)
      }

      return ''
    })

    const resetPicker = () => {
      isOpen.value = false
      activeTab.value = 0
      if (timePicker.value) {
        timePicker.value.selectingHour = false
      }
    }

    const showTimePicker = () => {
      activeTab.value = 1
    }

    const clearHandler = () => {
      resetPicker()
      date.value = ''
      time.value = '00:00:00'
      emit('input', null)
    }

    const confirmHandler = () => {
      resetPicker()
      emit('input', selectedDatetime.value)
    }

    const init = () => {
      if (props.value) {
        const initialFormattedDate = parseISO(props.value)

        date.value = format(initialFormattedDate, 'yyyy-MM-dd')
        time.value = format(initialFormattedDate, 'HH:mm:ss')
      }
    }

    onMounted(() => {
      init()
    })

    return {
      isOpen,
      activeTab,
      date,
      time,
      timePicker,

      formattedDateTime,
      selectedDatetime,
      validDate,

      resetPicker,
      showTimePicker,
      clearHandler,
      confirmHandler,

      icons: {
        mdiCalendar,
        mdiClockTimeThree,
      },
    }
  },
}
</script>

<style>

</style>
