import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"340"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"placeholder":"Input tanggal dan waktu","value":_vm.formattedDateTime,"rules":_vm.rules,"readonly":"","outlined":"","dense":"","hide-details":_vm.details}},'v-text-field',_vm.textFieldProps,false),on))]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VTabs,{attrs:{"fixed-tabs":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiCalendar)+" ")])],1),_c(VTab,{attrs:{"disabled":_vm.validDate}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiClockTimeThree)+" ")])],1)],1),_c(VTabsItems,{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTabItem,[_c(VDatePicker,_vm._b({attrs:{"full-width":"","no-title":"","locale":"id-ID"},on:{"input":_vm.showTimePicker},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-date-picker',_vm.datePickerProps,false))],1),_c(VTabItem,[_c(VTimePicker,_vm._b({ref:"timePicker",staticClass:"mb-8",attrs:{"locale":"id-ID","full-width":"","format":"24hr"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-time-picker',_vm.timePickerProps,false))],1)],1)],1),_c(VCardActions,{staticClass:"mt-n4"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","outlined":"","color":"primary"},on:{"click":_vm.clearHandler}},[_vm._v(" Reset ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.confirmHandler}},[_vm._v(" Pilih ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }